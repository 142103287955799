import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

import dayjs from 'dayjs'
import isEqual from 'react-fast-compare'
import useMobileDetect from 'use-mobile-detect-hook'
import queryString from 'query-string'

// TODO: tanQuery (react-query) or getInitialProps might be a better option here.
import {
  activityCategories as allActivities,
  cities as allCities,
  neighborhoods as allNeighborhoods,
  vibes as allVibes
} from 'vibemap-constants/dist/helpers'


import {
  getAllBoundaries,
  getBoundary
} from 'components/map/mapUtils'

import { getVibes } from 'vibemap-constants/dist/vibes.js'

import useFilterHooks from 'components/filters/useFilterHooks'
const { getDatesFromRange } = useFilterHooks()

const detectMobile = useMobileDetect()
const isAndroid = detectMobile.isAndroid()
const isIos = detectMobile.isIos()
const isMobile = detectMobile.isMobile()
const isDesktop = detectMobile.isDesktop()

// Default state used to seed the store and track URL params
export const defaultValues = {
  activities: [], // TODO: change to categories
  categories: [],
  cardLayout: 'grid',
  cardOr: 'horizontal',
  cardStyle: 'list',
  cities: [],
  clusterSize: 150,
  compact: false,
  currentLocation: {
    latitude: 37.8125262,
    longitude: -122.3054825,
    fromUser: false,
    userRequested: false,
    centerpoint: [100, 30]
  },
  zoomCurrent: 12,
  dateRange: 'half_year',
  dateEnd: undefined,
  desc: false, // Combine with showDescription
  editorial_category: undefined,
  editorialCategory: null,
  embedded: false,
  filters: {
    category: [],
    cities: [],
    vibesets: [],
    vibes: [],
    embedded: null,
    searchTerm: null,
    theme: null
  },
  filterOptions: ['categories', 'vibes', 'cities'],
  getCity: null,
  clipToBounds: false,
  fitBounds: true,
  fitMarkers: false,
  keepBounds: false,
  markerStyle: 'photo',
  mapPaddingBounds: 30,
  mapPos: 'right',
  mapTheme: 'vibemap',
  mapUpdated: false,
  minZoom: 8,
  numItemsPerPage: 250,
  numEventsPerPage: 200,
  numEventsShown: 100,
  numPlacesToLoad: 500,
  numPlacesToShow: 80,
  numItemsToShow: 100,
  numPlacesToStore: 400,
  ordering: '-score_combined',
  eventLayout : 'rows', // stacked "rows" or "columns"
  placeLayout: 'both',
  searchRadius: 12,
  shouldFetchEvents: false,
  shouldFetchPlaces: true,
  shouldShuffle: false,
  showAddress: false,
  showDescription: false,
  showNote: false,
  showTitle: false,
  showBoundary: true,
  showCats: true,
  showDateRange: !isMobile,
  showPopups: true,
  showPrint: true,
  showSearch: true,
  showTags: false,
  showVibes: true,
  showFilters: true,
  showHeat: true,
  showLocations: false,
  showAs: 'map',
  showTransit: false,
  storyScroll: false,
  tags: [],
  theme: null,
  zoom: 12,
  zoomDetails: 17,
}


function getInitialValue(param, defaultValue) {
  if (param) {
    const isBoolean = param == '1' || param == '0'
    // TODO: If is 1 or 0, convert to boolean
    return isBoolean ? param == '1' : param;
  }
  return defaultValue;
}

const getURLParams = () => {
  let params = {}
  let location = typeof window !== `undefined` ? window?.location : {}
  let urlParams = new URLSearchParams(location?.search ? location.search : '')
  params = Object.fromEntries(urlParams)
  // TODO: get any params from parent window
  try {
    let parent = window.parent
    const url_parent = (parent !== window) ? document.referrer : document.location
    const url_parsed = new URL(url_parent);
    const url_params = new URLSearchParams(url_parsed.search);
    // Combine the parent params with the window params
    for (const [key, value] of urlParams) {
      url_params.append(key, value);
    }

    params = Object.fromEntries(url_params)
    // console.log('DEBUG: urlParamsParent from embed ', url_parent, url_params, params);

  } catch (error) {
    console.log('DEBUG: error getting parent url ', error);
  }

  //console.log('DEBUG Params ', params);
  return params
}

// For embeds and iframes, Get URL params and listen for changes
const params = getURLParams()
if (typeof window !== `undefined` && window?.navigation) {
  window.navigation.addEventListener("navigate", (event) => {
    console.log('location changed!');
    const params = getURLParams()
  })
}

const cardOrInitial = getInitialValue(params.cardOr, defaultValues.cardOr)
const cardLayoutInitial = getInitialValue(params.cardLayout, defaultValues.cardLayout)
const compactInitial = getInitialValue(params.compact, defaultValues.compact)
const embeddedInitial = params.embedded ? params.embedded == '1' : false
const eventLayoutInitial = params.eventLayout ? params.eventLayout : defaultValues.eventLayout
const placeLayoutInitial = params.placeLayout ? params.placeLayout : defaultValues.placeLayout
const isLoggedInInitial = params.isLoggedIn ? params.isLoggedIn == '1' : true
const resizableInitial = params.resizable ? params.resizable === '1' : (params.resize ? params.resize === '1' : false);
const showBannerInitial = params.showBanner ? params.showBanner != '0' : true
const showFooterInitial = params.showFooter ? params.showFooter != '0' : true
const showHeaderInitial = params.showHeader ? params.showHeader != '0' : true
const themeInitial = params.theme ? params.theme : null

// Map default and params
const cardStyleInitial = params.cardStyle ? params.cardStyle : defaultValues.cardStyle
const clipToBoundsInitial = params.clipToBounds ? params.clipToBounds == '1' : defaultValues.clipToBounds
const keepBoundsInitial = params.keepBounds ? params.keepBounds == '1' : defaultValues.keepBounds
const fitBoundsInitial = params.fitBounds ? params.fitBounds == '1' : defaultValues.fitBounds
const fitMarkersInitial = params.fitMarkers ? params.fitMarkers == '1' : defaultValues.fitMarkers
const mapBearingInitial = getInitialValue(params.mapBearing, 0)
const markerStyleInitial = params.markerStyle ? params.markerStyle : defaultValues.markerStyle
const shouldFetchEventsInitial = params.shouldFetchEvents ? params.shouldFetchEvents == '1' : defaultValues.shouldFetchEvents
const shouldFetchPlacesInitial = params.shouldFetchPlaces ? params.shouldFetchPlaces == '1' : defaultValues.shouldFetchPlaces
const shouldShuffleInitial = params.shouldShuffle ? params.shouldShuffle == '1' : defaultValues.shouldShuffle
const showBoundaryInitial = params.showBoundary ? params.showBoundary == '1' : defaultValues.showBoundary
const showAddressInitial = params.showAddress ? params.showAddress == '1' : defaultValues.showAddress
const showDescriptionInitial = params.showDesc ? params.showDesc == '1' : defaultValues?.showDescription
const showNoteInitial = params.showNote ? params.showNote == '1' : defaultValues.showNote
const showAsInitial = params.showAs ? params.showAs : defaultValues.showAs
const showCirclesInitial = params.showCircles ? params.showCircles == '1' : false
const showHeatInitial = params.showHeat ? params.showHeat == '1' : defaultValues.showHeat
const showMarkersInitial = params.showMarkers ? params.showMarkers == '1' : true
const showTitleInitial = params.showTitle ? params.showTitle == '1' : defaultValues.showTitle


// 🔗 Routes and URL params
const page = typeof window !== `undefined` && window.location ?
  window.location.pathname :
  `/`

// Look up city in main list
// if not found, do an API call
// Hack for mismatched slugs
export const getCity = async (key, field = 'slug', exact = true) => {

  const found_exact = allCities.find(result => result[field] === key)
  //Conflict: let city = allCities.find(result => result[field] === key || result['name'].toLowerCase().includes(key))

  let city = exact
    ? found_exact || null
    : found_exact
      ? allCities.find(result => result[field] === key)
      : allCities.find(result => result['name'].toLowerCase().includes(key))

  if (city?.location) {
    city.centerpoint = [city.location.longitude, city.location.latitude]
    city.location.fromUser = true
  }

  if (!city) {
    city = await getBoundary(key)
    console.log('DEBUG: getting boundary for ', key);
  }

  if (!city) {
    console.log('DEBUG: city not found ', key);
  }

  return city
}


const setURLLocation = (params, path = location.pathname, shouldReplaceURL = false) => {
  const location = typeof window !== `undefined` && window ? window.location : null
  // Only set if different
  //console.log('DEBUG setURLLocation ', setURLParams)
  //console.log('DEBUG: why was url location set ', params, userChangedMap);

  let pagePath = path

  // Featured page routing
  let setActivitiesParam = true
  const isFeatured = page.includes('featured')
  if (isFeatured && setActivitiesParam) {
    // Safely destructure first item
    const { activities, ...otherParams } = params;
    const [activity] = params?.activities || []
    pagePath = `/featured/${activity ? activity : ''}`
    params = otherParams
  }

  // TODO: only set params that are set and different than the default
  Object.keys(params).forEach(key => {
    let newValue = params[key]
    let defaultValue = defaultValues[key]

    // Convert to boolean
    if (newValue === 1 || newValue === 0) {
      newValue = Boolean(newValue)
    }

    // If the value is the same as the default, remove it
    const isDefault = isEqual(newValue, defaultValue)

    // Or if undefined or empty, remove it
    const isEmpty = newValue == undefined || newValue == '' || newValue == null || newValue == []

    if (isEmpty || isDefault) {
      delete params[key]
    }

  })

  // TODO: setURLParams is a confusing name,
  // because it doesn't set state; it's a boolean to store data in the URL or not
  let queryParams = queryString.stringify(params, { skipNull: true })
  window.history.pushState(null, null, `?${queryParams}`)
}


// 🏪 Page Store
// State related to app page/screen context, user agent, etc.
const hasURLParams = params && Object.keys(params).length > 0
const selectedSection = params.section ? params.section : null

// Note: Tablet are detected as phone, so this fixes that
const isTablet = typeof document !== 'undefined'
  ? document?.body?.clientWidth < 880 && document?.body?.clientWidth > 500
  : false
const isPhone = isMobile && (isAndroid || isIos) && !isTablet
const isSSR = detectMobile.isSSR()

const isClient = typeof window !== 'undefined'
const height = typeof document !== 'undefined' ? document?.body?.clientHeight : null
const width = typeof document !== 'undefined' ? document?.body?.clientWidth : null
const isWide = width > 1200

export const pageStore = create(
  persist(
    (set, get) => ({
      // Getters
      cardLayout: cardLayoutInitial,
      cardOr: cardOrInitial,
      compact: compactInitial,
      debug: defaultValues.debug,
      embedded: embeddedInitial,
      hasParams: hasURLParams,
      height: height,
      isAndroid: isAndroid,
      isActive: false,
      isClient: isClient,
      isIos: false,
      isDesktop: isDesktop,
      isMobile: isMobile,
      isPhone: isPhone,
      isResizable: resizableInitial,
      isServer: false,
      isTablet: isTablet,
      isWide: isWide,
      isVibeGuide: false,
      listScrollPos: 0,
      pageCurrent: page,
      pageHasMap: true,
      eventLayout: eventLayoutInitial,
      placeLayout: placeLayoutInitial,
      section: selectedSection,
      sectionIndex: 0,
      showBanner: showBannerInitial,
      showFooter: showFooterInitial,
      showHeader: showHeaderInitial,
      showLogin: true,
      showCardMenu: getInitialValue(params.showCardMenu, true),
      showPrint: getInitialValue(params.showPrint, defaultValues.showPrint),
      themeClass: 'theme-default',
      themeName: themeInitial,
      themeObject: {},
      width: width,
      // TODO: this should be set by isLoggedin
      makeLinkFromTheme: ({
        link,
        slug,
        data_source_url
      }) => {
        const { themeObject, themeName } = get();

        const usePeoriaLink = themeName == 'peoria' && data_source_url?.includes('peoria')
        const useDynamicLink = themeName == 'downtown_tulsa' || themeName == 'downtown-tulsa' || themeName == 'la-jolla'
        const linkThemed = usePeoriaLink
          ? place?.properties?.data_source_url
          : useDynamicLink && themeObject?.cards?.open_to_page
            ? themeObject?.cards?.open_to_page + slug
            : link

        return linkThemed
      },
      // Setters
      setIsAndroid: (isAndroid) => set({ isAndroid }),
      setIsActive: (isActive) => set({ isActive }),
      setIsClient: (isClient) => set({ isClient }),
      setCardOr: (cardOr) => set({ cardOr }),
      setCompact: (compact) => set({ compact }),
      setEmbedded: (embedded) => set({ embedded }),
      setHeight: (height) => set({ height }),
      setIsIos: (isIos) => set({ isIos }),
      setIsDesktop: (isDesktop) => set({ isDesktop }),
      setIsMobile: (isMobile) => set({ isMobile }),
      setIsPhone: (isPhone) => set({ isPhone }),
      setIsServer: (isServer) => set({ isServer }),
      setIsTablet: (isTablet) => set({ isTablet }),
      setIsVibeGuide: (isVibeGuide) => set({ isVibeGuide }),
      setListScrollPos: (listScrollPos) => set({ listScrollPos }),
      setPageHasMap: (pageHasMap) => set({ pageHasMap }),
      setPlaceLayout: (placeLayout) => set({ placeLayout }),
      setShowLogin: (showLogin) => set({ showLogin }),
      setThemeClass: (themeClass) => set({ themeClass }),
      setThemeName: (themeName) => set({ themeName }),
      setThemeObject: (themeObject) => set({ themeObject }),
      setWidth: (width) => set({ width }),
    }),
    {
      name: 'page-storage',
      storage: createJSONStorage()
    }
  )
)


// 🏪 Filter Store
// State related to filter options and current selections
// Read defaults from URL params
// TODO: what about if the page context also tries to set the value?
const activitiesInitial = params.activities ? [].concat(params.activities) : []
const citiesInitial = params.cities
  ? [].concat(params.cities)
  : params.locations
    ? [].concat(params.locations)
    : []
const dateStartInitial = params.startDate || params.dateStart || dayjs().format('MM-DD-YYYY')
const endDateInitial = params.endDate || params.dateEnd || undefined;

const dateRangeInitial = params.dateRange
  ? params.dateRange
  : params.startDate
    ? 'custom'
    : defaultValues.dateRange

const dateStartObject = dayjs(dateStartInitial.replace('-', '/')) // Fix for Safari date parsing

const dateRangeStartEndInitial = params.startDate && params.endDate || params.dateStart && params.dateEnd
  ? { start: dayjs(dateStartInitial), end: dayjs(endDateInitial) }
  : getDatesFromRange(dateRangeInitial, dateStartInitial, endDateInitial, false)

const editorialCategoryInitial = params.editorial_category ? params.editorial_category : null
const minZoomInitial = params.minZoom ? parseInt(params.minZoom) : defaultValues.minZoom
const orderingInitial = params.ordering ? params.ordering : defaultValues.ordering
const searchInitial = params.search ? params.search : null
const tagsInitial = params.tags ? [].concat(params.tags) : []

const vibesInitial = params.vibes ? [].concat(params.vibes) : []
const radiusInitial = params.radius
  ? parseFloat(params.radius)
  : params.r
    ? parseFloat(params.r)
    : 12

const showCatsInitial = params.showCats ? params.showCats == '1' : true
const showEmptyInitial = params.showEmpty ? params.showEmpty == '1' : false
const showDateRangeInitial = params.showDateRange ? params.showDateRange == '1' : !isMobile && page.includes('events')
const showFiltersParam = params?.showFilters || params?.filters
const showFilterCountInitial = params.showFilterCount ? params.showFilterCount == '1' : false
const showFiltersInitial = showFiltersParam ? showFiltersParam == '1' : true
const showSearchInitial = params.showSearch ? params.showSearch == '1' : true
const isEvents = page.includes("events")
const showTagsInitial = params.showTags ? params.showTags == '1' : isEvents
const isFeaturedInitial = params.isFeatured &&  params.isFeatured == '1' || tagsInitial.includes('featured')
const showVibesInitial = params.showVibes ? params.showVibes == '1' : !isEvents
const zoomInitial = params.zoom ? parseInt(params.zoom) : 12

const initialFilters = {
  activities: activitiesInitial,
  categories: [],
  cities: citiesInitial,
  dateRange: dateRangeInitial,
  dateStart: dateStartInitial,
  dateEnd: endDateInitial,
  editorial_category: editorialCategoryInitial,
  search: searchInitial,
  tags: tagsInitial,
  vibes: vibesInitial,
}

const getUniqueNested = (array1, array2) => {
  const uniqueElements = array1.filter(element1 => {
    const found = array2.some(element2 => element2.includes(element1));
    return !found;
  });

  return uniqueElements;
}

export const filterStore = create((set, get) => ({
  // Getters
  activitiesCurrent: activitiesInitial, // TODO: rename to categories
  categoriesTop: [],
  citiesCurrent: [],
  dateRangeCurrent: dateRangeInitial,
  dateRangeStartEnd: dateRangeStartEndInitial,
  dateStart: dateStartInitial,
  dateEnd: endDateInitial,
  locationsTop: [],
  editorialCategory: editorialCategoryInitial,
  filters: initialFilters,
  hasFilters: false,
  hasActivitiesFilter: false,
  hasCitiesFilter: false,
  hasEditorialCategoryFilter: editorialCategoryInitial ? true : false,
  hasSearchFilter: false,
  hasTagsFilter: false,
  hasVibesFilter: false,
  isFeatured: isFeaturedInitial,
  numChipsToShow: 20,
  ordering: orderingInitial,
  relatedVibes: [],
  searchCurrent: null,
  // Accept either radius or r from the URL para
  searchQuery: null,
  searchRadius: radiusInitial,
  shouldSetURLParams: true,
  showDateRange: showDateRangeInitial,
  showCats: showCatsInitial,
  showFilters: showFiltersInitial,
  showFilterCount: showFilterCountInitial,
  showEmpty: showEmptyInitial,
  showSearch: showSearchInitial,
  showTags: showTagsInitial,
  showVibes: showVibesInitial,
  tagsCurrent: tagsInitial,
  tagsTop: [],
  tagsTopCount: {},
  vibesAvailable: allVibes,
  vibesCurrent: vibesInitial,
  vibesTop: [],
  vibesTopCount: {},
  // Setters
  setActivitiesCurrent: (activitiesCurrent) => set({ activitiesCurrent }),
  setCategoriesTop: (categoriesTop) => set({ categoriesTop }),
  setCitiesCurrent: (citiesCurrent) => set({ citiesCurrent }),
  setDateRangeCurrent: (dateRangeCurrent) => set({ dateRangeCurrent }),
  setDateRangeStartEnd: (dateRangeStartEnd) => {
    console.log('Setting dateRangeStartEnd to ', dateRangeStartEnd);
    set({ dateRangeStartEnd: { ...dateRangeStartEnd } });
  },
  setFilters: (filtersNew) => {
    const params = getURLParams()
    const paramsNew = { ...params, ...filtersNew }
    // Only include start/end date if dateRange is custom
    if (filtersNew.dateRange != 'custom') {
      delete paramsNew.dateStart
      delete paramsNew.dateEnd
    }

    const paramsChanged = !isEqual(paramsNew, params)
    const filtersCurrent = get().filters
    const shouldSetURLParams = get().shouldSetURLParams
    const filtersChanged = !isEqual(filtersNew, filtersCurrent)
    const emptyParams = params == ''

    if (filtersChanged) {
      set({ filters: filtersNew })
    }

    set({ hasFilters: filtersChanged && !emptyParams })
    set({ hasActivitiesFilter: filtersNew.activities && filtersNew.activities.length > 0 })
    set({ hasCitiesFilter: filtersNew.cities && filtersNew.cities.length > 0 })
    set({ hasEditorialCategoryFilter: filtersNew.editorial_category && filtersNew.editorial_category.length > 0 })
    set({ hasSearchFilter: filtersNew.search && filtersNew.search.length > 0 })
    set({ hasTagsFilter: filtersNew.tags && filtersNew.tags.length > 0 })
    set({ tagsCurrent: filtersNew.tags })
    set({ hasVibesFilter: filtersNew.vibes && filtersNew.vibes.length > 0 })
    if (shouldSetURLParams && !emptyParams) setURLLocation(paramsNew, paramsChanged)
  },
  setEditorialCategory: (editorialCategory) => set({ editorialCategory }),
  setLocationsTop: (locationsTop) => set({ locationsTop }),
  setNumChipsToShow: (numChipsToShow) => set({ numChipsToShow }),
  setSearchCurrent: (searchCurrent) => set({ searchCurrent }),
  setSearchQuery: (searchQuery) => set({ searchQuery }),
  setShouldSetURLParams: (shouldSetURLParams) => set({ shouldSetURLParams }),
  setTagsCurrent: (tagsCurrent) => set({ tagsCurrent }),
  setTagsTop: (tagsTop) => {
    const tagsTopCurrrent = get().tagsTop
    const tagsNew = tagsTop.filter(tagNew => {
      // Is tag in current list?
      const label = tagNew[0]
      const found = tagsTopCurrrent.some((tag) => tag.includes(label));
      return !found
    })

    const tagsCount = tagsTop.reduce((acc, tag) => {
      const label = tag[0]
      const count = tag[1]
      acc[label] = count
      return acc
    }, {})

    // Limit to state.numTagsTop
    const tagsUnique = [...tagsTopCurrrent, ...tagsNew].slice(0, get().numChipsToShow)
    set({ tagsTop: tagsUnique, tagsTopCount: tagsCount })
  },
  setVibesCurrent: (vibesCurrent) => set({ vibesCurrent }),
  setVibesAvailable: (vibesAvailable) => set({ vibesAvailable }),
  setVibesTop: (vibesTop => {
    const includeLowResults = false
    const lowResultThreshold = 1
    const vibesTopCurrrent = get().vibesTop
    const vibesNew = vibesTop.filter(vibeNew => {
      // Is vibe in current list?
      const label = vibeNew[0]
      const count = vibeNew[1]
      //const alreadyShown = vibesTopCurrrent.some((vibe) => vibe.includes(label));
      const isLowResult = count < lowResultThreshold && !includeLowResults
      return !isLowResult // && !alreadyShown
    })
    //console.log('DEBUG: vibesNew ', vibesTop, vibesNew);
    //console.log('DEBUG: vibesTopCurrrent ', vibesTopCurrrent);

    const vibesCount = vibesTop && vibesTop.reduce((acc, vibe) => {
      const label = vibe[0]
      const count = vibe[1]
      acc[label] = count
      return acc
    }, {}) // provide an initial value

    // Make unique list
    const vibesUnique = [...vibesNew]

    // Then resort it
    vibesUnique.sort((a, b) => { return b[1] - a[1] })

    set({ vibesTop: vibesUnique, vibesTopCount: vibesCount })
  }),
  setHasFilters: (hasFilters) => set({ hasFilters }),
  setHasActivitiesFilter: (hasActivitiesFilter) => set({ hasActivitiesFilter }),
  setHasCitiesFilter: (hasCitiesFilter) => set({ hasCitiesFilter }),
  setHasEditorialCategoryFilter: (hasEditorialCategoryFilter) => set({ hasEditorialCategoryFilter }),
  setHasSearchFilter: (hasSearchFilter) => set({ hasSearchFilter }),
  setHasTagsFilter: (hasTagsFilter) => set({ hasTagsFilter }),
  setHasVibesFilter: (hasVibesFilter) => set({ hasVibesFilter }),
  setSearchRadius: (searchRadius) => set({ searchRadius }),
  setDateStart: (dateStart) => {
    //console.log('DEBUG: setting dateStart ', dateStart);
    set({ dateStart })
  },
  setDateEnd: (dateEnd) => set({ dateEnd }),
  setShowCats: (showCats) => set({ showCats }),
  setShowDateRange: (showDateRange) => set({ showDateRange }),
  setShowFilters: (showFilters) => set({ showFilters }),
  setShowSearch: (showSearch) => set({ showSearch }),
  setVibesRelated: (vibesRelated) => set({ vibesRelated }),
}))


// 🏪 Data state
// State related to data fetching, loading, and caching
const vibesAll = getVibes()
const vibesAllDetailed = [...new Set(getVibes('all'))] // Handle any dups

const topLevelCategories = allActivities.activityCategories.filter(category => {
  const level = parseInt(category.level)
  if (level <= 2) return true
})

export const dataStore = create((set, get) => ({
  // Getters
  activitiesAll: allActivities,
  getActivityDetails: (key) => {
    const activities = get().activitiesAll
    const activity = activities.find(activity => activity.key == key)
    return activity
  },
  boundaries: [],
  boundariesFetch: async () => {
    const boundaries_cities_response = await getAllBoundaries('city')
    const boundaries_neighborhoods_response = await getAllBoundaries('neighborhood')
    const boundaries_cities = boundaries_cities_response?.results || []
    const boundaries_neighborhoods = boundaries_neighborhoods_response?.results || []

    // Filter and format cities
    const cities = boundaries_cities.filter(city => city.type == 'official')
    const neighborhoods = boundaries_neighborhoods.filter(neighborhood => neighborhood.type == 'official')

    set({
      boundaries: boundaries_cities,
      citiesAll: cities,
      neighborhoodsAll: neighborhoods
    })
  },
  categoriesAll: [],
  categories_top_slugs: allActivities.activityCategories.map(cat => cat.slug),
  citiesAll: [],
  citiesCurrent: [],
  neighborhoodsAll: [],
  neighborhoodsCurrent: [],
  allStories: [],
  detailsLoading: false,
  eventID: getInitialValue(params.id, null),
  eventCurrent: null,
  events: [],
  eventCards: [],
  eventsLoading: false,
  eventsLoaded: false,
  eventsError: null,
  eventsTotal: 0,
  hasPlace: false,
  numEvents: 0,
  numItemsPerPage: getInitialValue(params.perPage, defaultValues.numItemsPerPage),
  numEventsPerPage: getInitialValue(params.numEventsPerPage, defaultValues.numEventsPerPage),
  numEventsShown: getInitialValue(params.numEventsShown, defaultValues.numEventsShown),
  numPlaces: 0,
  numPlacesShown: defaultValues?.numPlacesToShow,
  currentPlace: null,
  currentPlaceGEOJSON: null,
  placeDetails: null,
  placeToAdd: null,
  placeFocused: null,
  places: [],
  placeCardsSuggested: [],
  placesInBounds: [],
  placesLoading: true,
  placesLoaded: false,
  placesError: null,
  placesScrolling: false,
  placesShouldRefresh: false,
  placeSwiped: false,
  placesTotal: 0,
  posts: [],
  stories: [],
  routeData: [],
  storiesLoading: false,
  storiesLoaded: false,
  storiesError: null,
  storiesTotal: 0,
  tagsAll: [],
  topLevelCategories: topLevelCategories,
  vibesAll: vibesAll,
  vibesAllDetailed: vibesAllDetailed,
  getVibeDetails: (key) => {
    const vibes = get().vibesAll
    const vibe = vibes.find(vibe => vibe.key == key)
    return vibe
  },
  // Setters
  addToPlaces: (place) => {
    const placesCurrent = get().places
    const placesNew = placesCurrent.concat(place)
    set({ places: placesNew })
  },
  setCitiesAll: (citiesAll) => set({ citiesAll }),
  setCitiesCurrent: (citiesCurrent) => set({ citiesCurrent }),
  setDetailsLoading: (detailsLoading) => set({ detailsLoading }),
  setNeighborhoodsAll: (neighborhoodsAll) => set({ neighborhoodsAll }),
  setNeighborhoodsCurrent: (neighborhoodsCurrent) => set({ neighborhoodsCurrent }),
  setAllStories: (allStories) => set({ allStories }),
  setAllTags: (allTags) => set({ allTags }),
  setVibesAll: (vibesAll) => set({ vibesAll }),
  setEvents: (events) => set({ events }),
  setEventCards: (eventCards) => set({ eventCards }),
  addEventCards: (eventCards) => {
    const eventCardsCurrent = get().eventCards
    const eventCardsNew = eventCardsCurrent.concat(eventCards)
    set({ eventCards: eventCardsNew })
  },
  setEventCurrent: (eventCurrent) => set({ eventCurrent }),
  setEventsLoading: (eventsLoading) => set({ eventsLoading }),
  setEventsLoaded: (eventsLoaded) => set({ eventsLoaded }),
  setEventsError: (eventsError) => set({ eventsError }),
  setEventsTotal: (eventsTotal) => set({ eventsTotal }),
  setHasPlace: (hasPlace) => set({ hasPlace }),
  setNumEvents: (numEvents) => set({ numEvents }),
  setNumEventsShown: (numEventsShown) => set({ numEventsShown }),
  setNumPlaces: (numPlaces) => set({ numPlaces }),
  setPlaceCurrent: (placeCurrent) => {
    // Keep as consistent object
    // Parse Geojson to flat object
    const is_geojson = placeCurrent?.geometry?.type == 'Point'

    const place_properties = is_geojson
      ? placeCurrent.properties
      : placeCurrent

    const place = placeCurrent
      ? {
        ...place_properties,
        geometry: placeCurrent?.geometry,
        id: placeCurrent?.id,
        key: placeCurrent.id,
        slug: place_properties.slug
      }
      : null

    return set({ placeCurrent: place, placeCurrentGEOJSON: placeCurrent })
  },
  setPlaceFocused: (placeFocused) => set({ placeFocused }),
  setPlaceToAdd: (placeToAdd) => set({ placeToAdd }),
  setPlaces: (places) => set({ places }),
  setPlaceCardsSuggested: (placeCardsSuggested) => set({ placeCardsSuggested }),
  setPlacesInBounds: (placesInBounds) => set({ placesInBounds }),
  setPlacesLoading: (placesLoading) => set({ placesLoading }),
  setPlacesLoaded: (placesLoaded) => set({ placesLoaded }),
  setPlacesError: (placesError) => set({ placesError }),
  setPlacesShouldRefresh: (placesShouldRefresh) => set({ placesShouldRefresh }),
  setPlacesSwiped: (placeSwiped) => set({ placeSwiped }),
  setPlacesScrolling: (placesScrolling) => set({ placesScrolling }),
  setPlacesTotal: (placesTotal) => set({ placesTotal }),
  setPosts: (posts) => set({ posts }),
  setRouteData: (routeData) => set({ routeData }),
  setStories: (stories) => set({ stories }),
  setStoriesLoading: (storiesLoading) => set({ storiesLoading }),
  setStoriesLoaded: (storiesLoaded) => set({ storiesLoaded }),
  setStoriesError: (storiesError) => set({ storiesError }),
  setStoriesTotal: (storiesTotal) => set({ storiesTotal }),
}))


// 🏪 Location state
// State about the app or user's current location
import { getLocation } from 'components/utils/getLocation'
// Determine current location based on hierarchy:
// 1. User Location (if they updated it)
// 2. Initial location is set by page or param (will already be set)
// 3. City Location (if set)
// 4. User's predicted location (fallback)

// TODO: Move to defaults
const currentLocationDefault = {
  latitude: 37.8125262,
  longitude: -122.3054825,
  centerpoint: [-122.3054825, 37.8125262],
  fromUser: false,
  userRequested: false,
  centerpoint: [100, 30]
}

let hasLocation = false
const hasCoords = params.latitude && params.longitude
const hasCityParam = citiesInitial && citiesInitial.length > 0
const hasLocationFromSlug = false

let cityInitial = null
if (hasCityParam) {
  const cityParamFirst = citiesInitial[0]
  let city = await getCity(cityParamFirst)

  if (city) {
    // TODO: why isn't the data already formatted like this?
    city.centerpoint = [city.location.longitude, city.location.latitude]
    city.latitude = city.location.latitude
    city.longitude = city.location.longitude
    city.location.fromUser = true
  }

  if (city) {
    cityInitial = city
  }
}


if (hasCoords || hasLocationFromSlug || hasCityParam) {
  hasLocation = true
}

const currentLocationInitial = hasCoords
  ? {
    latitude: parseFloat(params.latitude),
    longitude: parseFloat(params.longitude),
    fromUser: true,
    userRequested: false,
    centerpoint: [params.longitude, params.latitude],
  }
  : hasCityParam && cityInitial
    ? cityInitial
    : currentLocationDefault

const locationUser = hasCoords ? currentLocationInitial : null

export const locationStore = create((set, get) => ({
  allCities: [],
  cityCurrent: cityInitial,
  // Derive current location from currentCity?
  hasLocation: hasLocation,
  hasLocationFromPage: false,
  locationCurrent: currentLocationInitial,
  locationUser: locationUser,
  popup: null,
  tryLocation: false,
  zoomCurrent: zoomInitial,
  zoomPrevious: defaultValues?.zoom,
  checkLocation: (state) => {
    const currentLocation = getLocation()
    if (currentLocation) {
      set({ currentLocation: currentLocation })
      set({ hasLocation: true })
    } else {
      set({ currentLocation: currentLocationDefault })
      set({ hasLocation: true })
    }
  },
  setCityCurrent: (cityCurrent) => {
    // Set boundaries from cityCurrent, if available
    try {
      const boundaries = cityCurrent?.the_geom
      const mapStoreState = mapStore.getState()
      mapStoreState.setBoundaries(boundaries)
    } catch (error) {
      console.log('DEBUG: error setting city boundaries ', error);
    }

    return set({ cityCurrent })
  },
  setLocationCurrent: (locationCurrent, shouldSetURL = false) => {
    set({ locationCurrent: locationCurrent })
    // Set URL state with location
    const params = getURLParams()

    if (shouldSetURL) {
      let newParams = {
        ...params,
        latitude: locationCurrent?.latitude,
        longitude: locationCurrent?.longitude,
        //radius: searchRadius,
        //zoom: zoomCurrent
      }
      setURLLocation(newParams)
    }
  },
  setHasLocation: (hasLocation) => set({ hasLocation: hasLocation }),
  setTryLocation: (tryLocation) => set({ tryLocation: tryLocation }),
  setPopup: (popup) => set({ popup }),
  setZoomCurrent: (zoomCurrent) => set({ zoomCurrent }),
  setZoomPrevious: (zoomPrevious) => set({ zoomPrevious }),
}))


// 🏪 Map state
// State related to the map and map controls
export const mapStore = create((set, get) => ({
  // Map Getters
  boundaries: cityInitial?.the_geom,
  cardStyle: cardStyleInitial, // TODO: rename to cardListStyle to be more clear
  clipToBounds: clipToBoundsInitial,
  fitBounds: fitBoundsInitial,
  fitMarkers: fitMarkersInitial,
  keepBounds: keepBoundsInitial,
  mapBearing: mapBearingInitial,
  mapLocationCurrent: null,
  mapPaddingBounds: defaultValues.mapPaddingBounds,
  mapPos: defaultValues?.mapPos,
  mapTheme: defaultValues?.mapTheme,
  mapUpdateByUser: false,
  mapReady: false,
  mapUpdated: false,
  markerStyle: markerStyleInitial,
  minZoom: minZoomInitial,
  numImageMarkers: getInitialValue(params.numImageMarkers, 30),
  numPlacesToLoad: getInitialValue(params.numPlacesToLoad, defaultValues.numPlacesToLoad),
  numPlacesToShow: defaultValues?.numPlacesToShow,
  numPlacesToStore: defaultValues?.numPlacesToStore,
  printNow: getInitialValue(params.printNow, false),
  shouldFetchEvents: shouldFetchEventsInitial,
  shouldFetchPlaces: shouldFetchPlacesInitial,
  shouldCluster: getInitialValue(params.cluster, true),
  shouldIncludeTags: getInitialValue(params.includeTags, false),
  shouldNumber: getInitialValue(params.shouldNumber, false),
  shouldShuffle: shouldShuffleInitial,
  showBoundary: showBoundaryInitial,
  showAddress: showAddressInitial,
  showCategory: getInitialValue(params.showCategory, true),
  showDescription: showDescriptionInitial,
  showAs: showAsInitial,
  showCardImage: getInitialValue(params.showCardImage, true),
  showCardNum: getInitialValue(params.showCardNum, true),
  showMarkerImage: getInitialValue(params.showMarkerImage, true),
  showCircles: showCirclesInitial,
  showHeat: showHeatInitial,
  showMarkers: showMarkersInitial,
  showNote: showNoteInitial,
  showPopups: defaultValues?.showPopups,
  showRoute: getInitialValue(params.showRoute, false),
  showTitle: showTitleInitial,
  showTransit: defaultValues?.showTransit,
  storyScroll: isMobile,
  zoomDetails: defaultValues?.zoomDetails,
  zoomMin: 8,
  zoomMax: 20,
  // Map Setters
  setBoundaries: (boundaries) => set({ boundaries }),
  setCardStyle: (cardStyle) => set({ cardStyle }),
  setMapLocationCurrent: (mapLocationCurrent) => set({ mapLocationCurrent }),
  setMapReady: (mapReady) => set({ mapReady }),
  setMapUpdated: (mapUpdated) => set({ mapUpdated }),
  setMapUpdateByUser: (mapUpdateByUser) => set({ mapUpdateByUser }),
  setShouldNumber: (shouldNumber) => set({ shouldNumber }),
  setShouldIncludeTags: (shouldIncludeTags) => set({ shouldIncludeTags }),
  setShowAddress: (showAddress) => set({ showAddress }),
  setShowCategory: (showCategory) => set({ showCategory }),
  setShowCardImage: (showCardImage) => set({ showCardImage }),
  setShowCardNum: (showCardNum) => set({ showCardNum }),
  setShowMarkerImage: (showMarkerImage) => set({ showMarkerImage }),
  setShowDescription: (showDescription) => set({ showDescription }),
  setShowHeat: (showHeat) => set({ showHeat }),
  setStoryScroll: (storyScroll) => set({ storyScroll }),
}))

const navItemsInitial = [
  { title: 'Calendar of Events', path: '/events/', product: 'Events' },
  { title: 'Submit New Event', path: '/add-event', product: 'Events' },
  { title: 'Share via Newsletter', path: '/events?compact=1', product: 'Events' }
]

export const userStore = create(persist(
  (set, get) => ({
    // User Getters
    isLoggedIn: false,
    navItems: navItemsInitial,
    user: null,
    token: null, // Consider security implications
    // Actions
    logout: () => {
      set({ user: null, token: null });
      // Optionally, clear specific localStorage items if needed
    },
    // User Setters
    setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),
    setNavItems: (navItems) => set({ navItems }),
    setUser: (user) => set({ user }),
    setToken: (token) => set({ token }),
  }),
  {
    name: 'user-store', // unique name for localStorage key
    getStorage: () => localStorage, // specify localStorage as the storage option
  }
));

